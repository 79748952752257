import React, { useState, useEffect } from "react";
import fetchHelper from "../../../services/fetchHelper";
import classNames from "classnames";

const MyFilters = ({ onFilterChange, type }) => {
  const [customFilters, setCustomFilters] = useState({});
  const [showMyFilters, setShowMyFilters] = useState(false);
  const openDropdownClass = classNames({ show: showMyFilters });

  useEffect(() => {
    dataFilters();
  }, []);

  useEffect(() => {
    document.addEventListener("click", toggleMyFilters);

    return () => {
      document.removeEventListener("click", toggleMyFilters);
    };
  }, [showMyFilters]);

  const toggleMyFilters = () => {
    if (showMyFilters) {
      setShowMyFilters(false);
    }
  };

  const dataFilters = async () => {
    const { data } = await fetchHelper.getV2({
      url: `/patientmanagement/rest/user-preferences/${type}`,
    });

    setCustomFilters(data);
  };

  const applyCustomFilter = (filter) => {
    const mappedFilter = {};
    for (const [key, value] of Object.entries(filter.filters)) {
      mappedFilter[key] = {
        key,
        com: value.operator,
        value: value.value,
      };
    }

    onFilterChange({ ...mappedFilter });
  };

  return (
    <div className={"btn-group " + openDropdownClass}>
      <button className="btn dropdown-toggle" type="button" onClick={() => setShowMyFilters(!showMyFilters)}>
        My Filters
      </button>
      <div className={"dropdown-menu " + openDropdownClass}>
        <a className="dropdown-item" href="#">
          Save filters
        </a>
        <a className="dropdown-item" href="#">
          Edit filter
        </a>
        <div className="dropdown-divider"></div>
        <a className="default">Filters</a>
        {customFilters.preferencesPayload?.map((filter, i) => (
          <a key={i} className="dropdown-item" onClick={() => applyCustomFilter(filter)}>
            {filter.name}
          </a>
        ))}
      </div>
    </div>
  );
};

export default MyFilters;
